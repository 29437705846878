import { useEffect, useState } from "react";
import { createClient } from "@supabase/supabase-js";

const supabase = createClient("https://byrtehuvtcinvkymrdbg.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJ5cnRlaHV2dGNpbnZreW1yZGJnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzA3NTc1NzUsImV4cCI6MjA0NjMzMzU3NX0.MbbiaaOpDatKipLtrHO-jd6_csFYqUGha_2JwwFcFO4");

function App() {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    getCountries();
  }, []);

  async function getCountries() {
    const { data } = await supabase.from("countries").select();
    setCountries(data);
  }

  return (
    <ul>
      {countries.map((country) => (
        <li key={country.name}>{country.name}</li>
      ))}
    </ul>
  );
}

export default App;